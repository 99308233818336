import * as FontAwesome from "react-fontawesome";
import * as MXTS from "@maxxton/cms-mxts-api";
import * as React from "react";
import * as classNames from "classnames";
import * as moment from "moment";

import { AVAILABILITY_CONSTANTS, DATE_FORMAT } from "../../../utils/constants";
import { AccommodationType, Resort, Unit, getArrivalDateStayModel, getArrivalDateStayPopup, getArrivalDepartureModel, getMxtsEnv } from "../../mxts";
import { Button, Card, Modal, ModalBody, ModalHeader, NavLink } from "reactstrap";
import { ConfiguredLink, getLinkFromLinkingSpec } from "../../../utils/linking.util";
import { Content, isContentFilterChanged } from "../../../utils/content.util";
import { LocalizedButton, ResultsPanelButtonWidgetOptions } from "./";
import { MyEnvReducerAction, updateMyEnvState } from "../../../redux/actions/myEnvAction";
import { PriceMatrixAction, PriceMatrixActionType } from "../../../redux/actions/priceMatrixAction";
import { PriceTypes, TicketingTypes } from "../../page/activityPlanner/activityPlanner.enum";
import { RESTRICTION_RULE_OPTIONS, getAllBookRestrictionsByResourceIds, validateBookRestrictionConditions } from "../../../utils/bookRestriction.util";
import { StateHandler, warmupState } from "../../../utils/cacheWarmup.util";
import { UrlLinkParams, UrlParamsUtil } from "../../../utils/urlparam.util";
import { getI18nLocaleString, wrapProps } from "../../../i18n";
import { getInlineStyle, getPreconfiguredResultButtonClassNames } from "../../../utils/buttonOptions.util";
import { getUnitId, isAccoTypeArchived, isUnitArchived } from "../../../redux/reducers/myEnv/myEnv.util";
import { has, isEmpty } from "lodash";
import { isEqual, setOpacityOnHide, shouldDisableLink } from "../../../components/utils";
import { isLandscapeOrientation, isPortraitOrientation } from "../../../utils/devicewidth.util";
import { loadContent, retrieveLinkFromDynamicField } from "./resultsPanelButton.util";

import { ActionType } from "../../../redux/actions/index";
import { Activity } from "../../page/activityPlanner/activityPlanner.types";
import { AvailabilityAction } from "../../../redux/actions/availabilityAction";
import { AvailabilityState } from "../../../redux/reducers/availability.types";
import { AvailabilityUtil } from "../../../utils/availability.util";
import { ButtonIcon } from "../../../components/ButtonIcon";
import { DateMap } from "../../mxts/mxts.types";
import { DateRangePickerWrapper } from "../../../components/datepicker/DateRangePicker/DateRangePickerWrapper";
import { Dispatch } from "redux";
import { DispatchOpenLinkedTabOptions } from "../resultsPanelUtil";
import { DynamicFilter } from "../../../redux/reducers/dynamicFilter.types";
import { DynamicWidgetBaseProps } from "../../dynamic/dynamicWidget.types";
import { FilterChangeAction } from "../../../redux/actions/dynamicFilterAction.types";
import { FocusedInputShape } from "react-dates";
import { FrontendPageEditState } from "../../../redux/reducers/frontendPageEditReducer";
import { MyEnvState } from "../../../redux/reducers/myEnv/myEnvState";
import { ReservationContainerCRPProps } from "../../dynamic/reservation/container/reservationContainer.types";
import { START_DATE } from "../../../components/datepicker/datepicker.util";
import { SpecialLegend } from "../../../components/Legend";
import { State } from "../../../redux";
import { connect } from "react-redux";
import { dynamicFilterType } from "../../../redux/reducers/dynamicFilter.enum";
import { getActivitySections } from "../../page/activityPlanner/activityPlanner.util";
import { getLocalizedContent } from "../../../utils/localizedContent.util";
import { isClientSide } from "../../../utils/generic.util";
import namespaceList from "../../../i18n/namespaceList";
import { stringify } from "query-string";

type Moment = moment.Moment;

const { NO_SPECIAL } = AVAILABILITY_CONSTANTS;

interface DynamicButtonBaseProps extends DynamicWidgetBaseProps<ResultsPanelButtonWidgetOptions> {
    resort?: Resort;
    accommodationType?: AccommodationType;
    unit?: Unit;
    activity?: Activity;
    url: string;
    target?: string;
    isMapWidget?: boolean;
    dispatchOpenLinkedTabAction?: (options: DispatchOpenLinkedTabOptions) => void;
}

export interface DynamicButtonProps extends DynamicButtonBaseProps, DynamicButtonStoreProps, ReservationContainerCRPProps, DynamicButtonDispatchProps {}

interface DynamicButtonStoreProps {
    myEnvState: MyEnvState;
    dynamicFilter: DynamicFilter;
    availabilityState: AvailabilityState;
    frontendPageEditState: FrontendPageEditState;
}

interface SmartButtonContent {
    smartButtonLabel: string;
    smartButtonCondition: string;
}

interface DynamicButtonDispatchProps {
    dispatchAction: Dispatch<FilterChangeAction | AvailabilityAction | MyEnvReducerAction | PriceMatrixAction>;
}

interface ResultsPanelButtonState {
    isFetching: boolean;
    arrivalDateModalOpen?: boolean;
    modalStartDate: Moment | null;
    modalStayPeriodDefId: number | null;
    modalStartDatefocus: boolean;
    endDate: Moment | null;
    focusedInput: FocusedInputShape | null;
    chosenAccoType?: AccommodationType;
    modalAvailableStayPeriodDefs?: MXTS.StayPeriodDef[];
    modalAvailableSpecialStayPeriodDefs?: MXTS.StayPeriodDef[];
    arrivalDateMap?: DateMap;
    durations?: number[];
    amenityCodes?: string[];
    specialArrivalDateMap?: DateMap;
    specialDurations?: number[];
    allStayPeriodDefs?: MXTS.StayPeriodDef[];
    isSpecialSelected?: boolean;
    content?: Content | Content[];
    allBookableDurations?: number[];
    unitSpecialName?: string;
    link: ConfiguredLink;
    isMobile: boolean;
    isSelectedBookingRestricted: boolean;
    showDetailsIdInUrl: boolean;
}

type ResultsPanelButtonStateHandler = StateHandler<DynamicButtonProps, ResultsPanelButtonState>;

export class DynamicResultsPanelButtonBase extends React.Component<DynamicButtonProps, ResultsPanelButtonState> {
    private currentAccommodationType?: AccommodationType;
    private currentUnit?: Unit;
    private currentResort?: MXTS.Resort;
    private isButtonActive = false;
    private activity?: Activity;

    public static async warmupCache(props: DynamicButtonProps): Promise<ResultsPanelButtonState> {
        return warmupState(props, DynamicResultsPanelButtonBase.defaultState(props), async (stateHandler) => {
            const { availabilityResult, env } = props.availabilityState;
            const stayPeriodDefIds = availabilityResult?.response.stayPeriodDefs || [];

            await loadContent({ props: stateHandler.props, widgetOptionsId: props.options._id }).then((content) => stateHandler.setState({ content }));

            if (env) {
                const result = await props.context.mxtsApi.stayPeriodDefs(env, { stayPeriodDefIds, size: 1000 });
                stateHandler.setState({ allStayPeriodDefs: result.content });
            }
            await DynamicResultsPanelButtonBase.handleAmenities(stateHandler);
        });
    }

    private static defaultState(props: DynamicButtonProps): ResultsPanelButtonState {
        return {
            isFetching: false,
            modalStartDate: DynamicResultsPanelButtonBase.getModalStartDate(props),
            modalStayPeriodDefId: props.dynamicFilter.stayperioddefid ? props.dynamicFilter.stayperioddefid : null,
            modalStartDatefocus: props.dynamicFilter.startdate ? false : true,
            endDate: props.dynamicFilter.enddate ? moment(props.dynamicFilter.enddate, DATE_FORMAT.DEFAULT) : null,
            focusedInput: props.dynamicFilter.startdate && !props.dynamicFilter.enddate ? "endDate" : "startDate",
            modalAvailableStayPeriodDefs: [],
            content: undefined,
            allBookableDurations: [],
            link: {
                url: props.url,
                target: props.target,
            },
            isMobile: false,
            isSelectedBookingRestricted: false,
            unitSpecialName: "",
            showDetailsIdInUrl: false,
        };
    }

    constructor(props: DynamicButtonProps) {
        super(props);
        this.state = {
            ...DynamicResultsPanelButtonBase.defaultState(props),
        };
    }

    public shouldComponentUpdate(nextProps: DynamicButtonProps, nextState: ResultsPanelButtonState): boolean {
        // This is to ensure that on the search containers we update the link when there's a change in state or in the selected filters
        if (isEqual(this.state, nextState) && isEqual(this.props.dynamicFilter, nextProps.dynamicFilter)) {
            return false;
        }
        return true;
    }

    public async componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<ResultsPanelButtonState>) {
        const {
            resort,
            accommodationType,
            unit,
            isMyEnvWidget,
            activity,
            options: { useDynamicFieldAsLink },
        } = this.props;
        const { content: stateContent } = this.state;
        const propContent: Content = resort || accommodationType || unit;
        if ((!isMyEnvWidget && propContent && !isEqual(stateContent, propContent)) || isContentFilterChanged(prevProps, this.props, false)) {
            loadContent({ props: this.props, widgetOptionsId: this.props.options._id }).then((content) => this.setState({ content }));
        }

        if (activity) {
            this.checkActivityDetailsIdRequirement();
        }

        if (stateContent && !isEqual(prevState.content, stateContent)) {
            // using requestQuote to identify isUnitType as for now we cannot trust any other field
            const isUnitType = (stateContent as Unit)?.unitId && !has(stateContent, "requestQuote");
            const isAccommodationType = (stateContent as AccommodationType)?.resourceId && !isUnitType;
            const isResort = (stateContent as Resort)?.resortId && !isAccommodationType;
            this.currentAccommodationType = isAccommodationType ? (stateContent as AccommodationType) : undefined;
            this.currentUnit = isUnitType ? (stateContent as Unit) : undefined;
            this.currentResort = isResort ? (stateContent as Resort) : undefined;

            const link = await this.getLinkFromWidgetOptions();
            this.setState({
                link: {
                    url: link.url || this.props.url,
                    target: link.target || this.props.target,
                    rel: link.rel,
                    anchor: link.anchor,
                },
            });
        }

        if (useDynamicFieldAsLink && !isEqual(prevState?.content, this.state?.content)) {
            retrieveLinkFromDynamicField(this.props, this.state.content).then((link) => link && this.setState({ link }));
        }

        this.changeOrientation();
        window.removeEventListener("resize", this.changeOrientation);
        window.addEventListener("resize", this.changeOrientation);
    }

    public componentDidMount() {
        const { availabilityResult } = this.props.availabilityState;
        const { env } = this.props.availabilityState;
        const stayPeriodDefIds = availabilityResult?.response.stayPeriodDefs || [];

        this.changeOrientation();
        if (isClientSide()) {
            window.addEventListener("orientationchange", this.changeOrientation);
        }
        loadContent({ props: this.props, widgetOptionsId: this.props.options._id }).then((content) => this.setState({ content }));

        if (this.props.activity) {
            this.checkActivityDetailsIdRequirement();
        }

        if (env) {
            this.props.context.mxtsApi
                .stayPeriodDefs(env, { stayPeriodDefIds, size: 1000 })
                .then((result) => result.content)
                .then((availableStayPeriodDefs: MXTS.StayPeriodDef[]) => {
                    this.setState({ allStayPeriodDefs: availableStayPeriodDefs });
                });
        }

        // Disabling this dispatch for bookings engine URL as it dispatches action for each result

        /* This will be removed in the future and then the URL in the Map can be configured
           for redirecting to BM on Complete Map InfoWindow Click */

        // this.handleBookingEngineUrl(this.props.url);
        DynamicResultsPanelButtonBase.handleAmenities(this);
    }

    public componentWillUnmount() {
        window.removeEventListener("orientationchange", this.changeOrientation);
        window.removeEventListener("resize", this.changeOrientation);
    }

    // eslint-disable-next-line max-lines-per-function
    public render(): JSX.Element | null {
        const {
            isFetching,
            modalStartDate,
            modalStayPeriodDefId,
            modalStartDatefocus,
            arrivalDateModalOpen,
            chosenAccoType,
            modalAvailableStayPeriodDefs,
            modalAvailableSpecialStayPeriodDefs,
            arrivalDateMap,
            specialArrivalDateMap,
            isSpecialSelected,
            link,
        } = this.state;
        const {
            className,
            myEnvState,
            context: { currentLocale, site },
            isMyEnvWidget,
            options,
            esReservationResult,
            dynamicFilter,
            activity,
            frontendPageEditState,
        } = this.props;
        const hideWidget = setOpacityOnHide(options);
        const { currentAccommodationType: accommodationType, currentUnit: unit, currentResort: resort } = this;
        if (options.addPreferenceUnitToReservation && unit) {
            return this.addPreferenceUnitToReservationLink(unit);
        }
        const localContent: LocalizedButton | null = getLocalizedContent({ site, currentLocale, localizedContent: options.localized });
        const bookingIsRestrictedMsg = accommodationType?.localizedBookingIsRestrictedMsg
            ? getLocalizedContent({ site, currentLocale, localizedContent: accommodationType.localizedBookingIsRestrictedMsg })?.bookingIsRestrictedMsg
            : "";
        let bookUrl = options?.useDynamicFieldAsLink ? link?.url : UrlParamsUtil.getBmUrlWithQueryParams(link.url, this.getUrlParams());
        if (link.anchor) {
            bookUrl = `${bookUrl}#${link.anchor}`;
        }
        const isUnavailableAccommodation = this.checkIsUnavailableAccommodation();
        const linkWithSelectedReservation = esReservationResult && UrlParamsUtil.getMyEnvLink(dynamicFilter, esReservationResult, link);
        const displaySubjectPopup = options.showTravelGroupPopup && this.getSmartButtonContent()?.smartButtonCondition === "subject";
        if (options.toggleButton) {
            return this.renderToggleBtn();
        }
        const isFreeActivity = options.useWithActivityPlanner && localContent?.freeActivityButtonText && activity?.resourceActivity.resortActivity.priceType === "FREE";

        let buttonText;
        if (options.showActivityPlannerButtonLabel) {
            const { priceType, ticketingType } = activity?.resourceActivity?.resortActivity || {};
            const isPaidActivity = priceType === PriceTypes.PAID;
            const isFreeActivityStatus = priceType === PriceTypes.FREE;
            const isActivityNonReservable = ticketingType === TicketingTypes.NO_TICKETS;
            buttonText =
                isActivityNonReservable && (isPaidActivity || isFreeActivityStatus)
                    ? getI18nLocaleString(namespaceList.widgetActivityPlanner, "viewButtonLabel", currentLocale, site)
                    : getI18nLocaleString(namespaceList.widgetActivityPlanner, "viewAndReserveButtonLabel", currentLocale, site);
        }
        const linkButtonText =
            options.showQuote && localContent && (accommodationType?.requestQuote || isUnavailableAccommodation)
                ? localContent.quoteButtonText
                : isFreeActivity
                ? localContent?.freeActivityButtonText
                : localContent?.buttonText;
        const linkText = buttonText || linkButtonText;
        const hasDynamicFieldLink = options?.useDynamicFieldAsLink && link?.url;
        if (options.addTheSelectedReservationIdToTheUrl && esReservationResult) {
            return (
                <NavLink
                    href={linkWithSelectedReservation}
                    className={classNames("button", className, getPreconfiguredResultButtonClassNames(options.buttonConfiguration))}
                    disabled={shouldDisableLink(!!frontendPageEditState.isFrontEndEditable)}
                >
                    {linkText}
                </NavLink>
            );
        }
        return (localContent && !options.addTheSelectedReservationIdToTheUrl && (resort || accommodationType || unit) && this.shouldDisplayLink(localContent, accommodationType)) ||
            hasDynamicFieldLink ? (
            <div
                className={`results-button d-inline-block ${hideWidget} ${classNames({
                    "unit-archived": isUnitArchived(esReservationResult || myEnvState.selectedReservation),
                    "resource-archived": isAccoTypeArchived(esReservationResult || myEnvState.selectedReservation),
                })}`}
            >
                {esReservationResult && isMyEnvWidget && this.isTargetPathSameAsCurrentPath(bookUrl) ? (
                    <a
                        onClick={this.updateSelectedMyEnvReservation(esReservationResult, this.props.dispatchAction)}
                        className={classNames("button", className, getPreconfiguredResultButtonClassNames(options.buttonConfiguration))}
                        style={getInlineStyle(options.buttonConfiguration)}
                        target={link.target}
                    >
                        {linkText}
                    </a>
                ) : (
                    <NavLink
                        href={bookUrl}
                        onClick={this.handleBookLinkClick(bookUrl, accommodationType, unit)}
                        className={classNames(
                            "button",
                            className,
                            getPreconfiguredResultButtonClassNames(options.buttonConfiguration),
                            activity ? `resort-activity-${activity.resourceActivity.resortActivityId}` : ""
                        )}
                        style={getInlineStyle(options.buttonConfiguration)}
                        target={link.target}
                        disabled={shouldDisableLink(!!frontendPageEditState.isFrontEndEditable)}
                    >
                        <ButtonIcon buttonConfiguration={options.buttonConfiguration} />
                        {linkText}
                    </NavLink>
                )}
            </div>
        ) : localContent && (resort || accommodationType || unit) && options.showDateStayPopup && !accommodationType?.requestQuote && !isUnavailableAccommodation ? (
            <div className={`${hideWidget}`}>
                {chosenAccoType && this.isPopupEnabled() && !options.dateRangePicker
                    ? getArrivalDateStayPopup(
                          chosenAccoType,
                          this.handleSetNoSpecial,
                          this.handleArrivalDateModal,
                          this.handleStartDateFocusChange.bind(modalStartDate),
                          this.handleModalStartDateChange,
                          this.handleModalStayChange,
                          modalStartDate,
                          modalStayPeriodDefId,
                          modalStartDatefocus,
                          this.getBookLink(chosenAccoType),
                          options.dateFormat,
                          arrivalDateModalOpen,
                          isFetching,
                          modalAvailableStayPeriodDefs,
                          arrivalDateMap,
                          specialArrivalDateMap,
                          modalAvailableSpecialStayPeriodDefs,
                          isSpecialSelected,
                          this.handleSpecialClick
                      )
                    : this.isPopupEnabled() && options.dateRangePicker && !displaySubjectPopup && !dynamicFilter.subjectPopup && this.getArrivalDepartureDatePopup()}
                {this.isPopupEnabled() || displaySubjectPopup
                    ? this.getBookButton(() => this.selectAccoType((resort as Resort)?.curatedAccommodation || accommodationType || unit))
                    : this.getBookLink(accommodationType || unit)}
            </div>
        ) : accommodationType?.isBookingRestricted ? (
            <small className={`alert alert-warning feedback-booking-restriction ${hideWidget}`}>
                <span>{bookingIsRestrictedMsg}</span>
            </small>
        ) : null;
    }

    private getLinkFromWidgetOptions = async (): Promise<ConfiguredLink> => {
        const { options: widgetOptions, context } = this.props;
        const { currentLocale, site } = context;

        const buttonLocalization: LocalizedButton | null = getLocalizedContent({ site, currentLocale, localizedContent: widgetOptions.localized });

        if (widgetOptions.showQuote && buttonLocalization && this.currentAccommodationType && (this.currentAccommodationType.requestQuote || this.checkIsUnavailableAccommodation())) {
            return { url: buttonLocalization.quoteLinkUrl };
        }

        const link = await getLinkFromLinkingSpec({
            context,
            linkingSpecOptions: widgetOptions.linking,
            accommodationKindId: this.currentAccommodationType?.accommodationkindId,
        });
        return link;
    };

    private isTargetPathSameAsCurrentPath(bookUrl: string) {
        // Compare pathname. Host doesn't matter
        return location.pathname === new URL("https://maxxton.net" + bookUrl).pathname;
    }

    private loadAllBookableDurations = async () => {
        const allBookableDurations = await this.getAllBookableDurations();
        this.setState({ allBookableDurations });
    };

    private shouldDisplayLink = (localContent: LocalizedButton, accommodationType: any) => {
        const { options, url } = this.props;
        const isUnavailableAccommodation = this.checkIsUnavailableAccommodation();
        if (options.showQuote) {
            if (!options.showDateStayPopup) {
                return true;
            }
            return !!(localContent && accommodationType && (accommodationType.requestQuote || isUnavailableAccommodation));
        }
        if (isUnavailableAccommodation) {
            return false;
        }
        if (options.buttonConfiguration.displayLink || (!options.showDateStayPopupInfo && !options.showDateStayPopup)) {
            return !!url;
        }
        return false;
    };

    private static async handleAmenities(stateHandler: ResultsPanelButtonStateHandler) {
        const { dynamicFilter, context } = stateHandler.props;
        const apiCallOptions = await getMxtsEnv(context);
        if (dynamicFilter.amenities) {
            // If some/all amenity codes are absent in dynamicFilter, fetching them
            const amenityCodes: string[] = [];
            if (dynamicFilter.amenities && dynamicFilter.amenities.length > 0) {
                const amenities = await context.mxtsApi.amenities(apiCallOptions, { identifier: dynamicFilter.amenities.join(",") }).then((am) => am.content);
                if (amenities) {
                    amenities.forEach((element: MXTS.Amenity) => {
                        amenityCodes.push(element.identifier);
                    });
                }
            }
            stateHandler.setState({ amenityCodes });
        }
    }
    // eslint-disable-next-line max-lines-per-function
    private getUrlParams = () => {
        const { dynamicFilter, context, options, isMyEnvWidget, esReservationResult, myEnvState, activity, availabilityState } = this.props;
        const { currentAccommodationType: accommodationType, currentUnit: unit, currentResort: resort } = this;
        const { content, showDetailsIdInUrl } = this.state;
        const locale = context.currentLocale.code;
        const localeId = context.currentLocale.locale;
        const localContent = options.linking.localizedLinkButtonOptions?.find((lc) => lc.locale === localeId);
        const params = UrlParamsUtil.getUrlParamsFromFilter(dynamicFilter);
        const resourceId =
            Array.isArray(dynamicFilter.resourceid) && dynamicFilter.resourceid.length === 1
                ? dynamicFilter.resourceid[0]
                : !Array.isArray(dynamicFilter.resourceid)
                ? dynamicFilter.resourceid
                : undefined;
        if (locale && unit) {
            params.lan = locale;
        }
        if (dynamicFilter.startdate && dynamicFilter.enddate && !dynamicFilter.duration && !dynamicFilter.stayperioddefid) {
            params.enddate = dynamicFilter.enddate;
        }
        if (resourceId || accommodationType?.resourceId) {
            params.resourceid = accommodationType?.resourceId || dynamicFilter.resourceid;
        } else if (unit) {
            params.resourceid = unit.resourceId;
        } else if (resort) {
            params.resortid = resort.resortId;
            if (!options.removedResourceIdFromUrlParams) {
                params.resourceid = (resort as Resort).curatedAccommodation?.resourceId;
            }
        }
        if (dynamicFilter.unitid) {
            if (localContent?.useExternalUrl) {
                params.objectid = dynamicFilter.unitid;
            } else {
                params.unitid = dynamicFilter.unitid;
            }
        } else if (unit?.unitId) {
            if (localContent?.useExternalUrl) {
                params.objectid = unit.unitId;
            } else {
                params[isMyEnvWidget ? "selectedOwnerUnitId" : "unitid"] = unit.unitId;
            }
        }
        if (dynamicFilter?.specialcode?.length) {
            if (localContent?.useExternalUrl) {
                params.actiecode = dynamicFilter ? dynamicFilter.specialcode[0] : undefined;
            } else {
                let specialCode = (content as AccommodationType | Unit | undefined)?.specialCode;
                const currentUnit = availabilityState.availabilityResult?.response?.units?.find((unit) => unit?.unitId === this.props.unit?.unitId);
                const specials = currentUnit?.specialCodes;
                // If there are several special codes available within the availability, it means there's a combined offer
                if (specials?.length && specials.length > 1) {
                    specialCode = specials?.filter((special: string) => special.toLowerCase() === dynamicFilter?.specialcode?.[0]?.toLowerCase());
                }
                params.specialcode = specialCode;
            }
        }
        if (dynamicFilter.amenities?.length && this.state.amenityCodes?.length && !params.amenities?.length) {
            params.amenity = Array.from(this.state.amenityCodes.values()).join();
        }

        if (isMyEnvWidget && esReservationResult?.reservedResources?.length && params?.resourceid) {
            params.unitid = getUnitId(esReservationResult);
        }
        if (isMyEnvWidget && esReservationResult?.reservation?.reservationId) {
            params.selectedreservationid = `${esReservationResult.reservation.reservationId}`;
        }

        if (accommodationType?.alternative) {
            // Note:- This check is for flexible days, if flexible dates are available then take that date for book now next page.
            const flexiStartDate = moment(accommodationType?.arrivalDate, DATE_FORMAT.MXTS).format(DATE_FORMAT.DEFAULT);
            const flexiEndDate = moment(accommodationType?.departureDate, DATE_FORMAT.MXTS).format(DATE_FORMAT.DEFAULT);
            if (flexiStartDate !== dynamicFilter.startdate) {
                params.startdate = flexiStartDate;
            }
            if (flexiEndDate !== dynamicFilter.enddate) {
                params.enddate = flexiEndDate;
            }
        }

        // Remove additional resortIds present in the url parameters based on filters
        if (params.resortids?.length) {
            const contentTypeResortId = this.props.accommodationType?.resortId || this.props.resort?.resortId;
            if (contentTypeResortId) {
                params.resortids = params.resortids.filter((resortId) => resortId === contentTypeResortId);
            }
        }

        if (activity) {
            // Set activity params
            const date = activity.day ? moment(activity.day, DATE_FORMAT.MXTS).format(DATE_FORMAT.DEFAULT) : "";
            params.resortids = [activity.resourceActivity.resortId];
            params.startdate = date;
            params.enddate = date;
            params.resortActivityId = activity.resourceActivity.resortActivity.resortActivityId;
            params.resourceid = activity.resourceId;
            if (!activity?.showMainActivity || showDetailsIdInUrl) {
                // do not set these params for when we show main activity in CRP as the specific resourceActivityDetailsId will be selected in section table.
                params.resourceActivityDetailsId = activity.resourceActivityDetailsId;
            }
        }

        if (!params.stateUuid && isMyEnvWidget && !params.selectedOwnerUnitId && myEnvState.ownerState?.selectedUnitId) {
            params.selectedOwnerUnitId = myEnvState.ownerState.selectedUnitId;
        }

        if (options.disableDefaultDuration) {
            delete params.stayperioddefid;
        }
        return params;
    };

    private renderToggleBtn = () => {
        const { options, context, activity } = this.props;
        const { currentAccommodationType: accommodationType, currentUnit: unit, currentResort: resort } = this;
        const localeId = context.currentLocale.locale;
        const localContent = (options.localized || []).find((lc) => lc.locale === localeId);
        const { link } = this.state;
        return (
            <a
                className={classNames("dynamic-btn-widget", "btn-toggle-info", `${options.buttonConfiguration.buttonSize}`, {
                    ["display-link"]: options.buttonConfiguration.displayLink && !options.buttonConfiguration.useAsBorderedButton,
                    ["he-1"]: options.buttonConfiguration.displayLink && options.buttonConfiguration.linkHoverEffect === "he-1",
                    ["he-2"]: options.buttonConfiguration.displayLink && options.buttonConfiguration.linkHoverEffect === "he-2",
                    [`background-color-${options.buttonConfiguration.buttonColor}`]: options.buttonConfiguration.buttonColor?.includes("theme"),
                    [`color-${options.buttonConfiguration.linkColor}`]: options.buttonConfiguration.linkColor?.includes("theme"),
                    ["button-primary"]: options.buttonConfiguration.usePreConfiguredButton && options.buttonConfiguration.preConfiguredButton === "button-primary",
                    ["button-secondary"]: options.buttonConfiguration.usePreConfiguredButton && options.buttonConfiguration.preConfiguredButton === "button-secondary",
                    ["bordered-button"]: options.buttonConfiguration.useAsBorderedButton,
                    ["icon-right"]: options.buttonConfiguration.showIcons && options.buttonConfiguration.iconRight,
                })}
                onClick={this.toggleBlock}
                style={getInlineStyle(options.buttonConfiguration)}
                data-toggle-target={
                    activity
                        ? `${options.ElementToToggle}-${(activity as Activity).resourceActivityDetailsId}`
                        : accommodationType
                        ? `${options.ElementToToggle}-${(accommodationType as AccommodationType).resourceId}`
                        : unit
                        ? `${options.ElementToToggle}-${(unit as Unit).unitId}`
                        : resort
                        ? `${options.ElementToToggle}-${(resort as Resort).resortId}`
                        : options.ElementToToggle
                }
                target={link.target}
            >
                <ButtonIcon buttonConfiguration={options.buttonConfiguration} />
                <span className="togglebtn-txt">{localContent && localContent!.buttonText}</span>
            </a>
        );
    };

    private toggleBlock = () => {
        const { options, dispatchAction, activity } = this.props;
        const { currentAccommodationType: accommodationType, currentUnit: unit } = this;
        const toggleBlock = document.querySelector(
            "[data-toggle-element=" +
                `'${options.ElementToToggle}-${
                    activity ? (activity as Activity).resourceActivityDetailsId : accommodationType ? (accommodationType as any).resourceId : unit ? (unit as any).unitId : ""
                }` +
                "']"
        );
        const toggleBtns = document.querySelectorAll(
            "[data-toggle-target=" +
                `'${options.ElementToToggle}-${
                    activity ? (activity as Activity).resourceActivityDetailsId : accommodationType ? (accommodationType as any).resourceId : unit ? (unit as any).unitId : ""
                }` +
                "']"
        );

        if (toggleBlock) {
            if (!toggleBlock.classList.contains("toggle-block-hidden")) {
                toggleBlock.classList.add("toggle-block-hidden");
                toggleBlock.classList.remove("toggle-block-visible");
            } else {
                toggleBlock.classList.remove("toggle-block-hidden");
                toggleBlock.classList.add("toggle-block-visible");
            }

            Array.from(toggleBtns).forEach((button: any) => {
                if (!toggleBlock.classList.contains("toggle-block-hidden")) {
                    button.classList.add("active");
                } else {
                    button.classList.remove("active");
                }
            });
        }
        const action: PriceMatrixAction = {
            type: ActionType.priceMatrix,
            actionType: PriceMatrixActionType.SHOW_MATRIX_WIDGET,
            payload: {
                isMatrixFetched: !this.isButtonActive,
                fetchedPriceMatrixResourceId: accommodationType?.resourceId,
            },
        };
        dispatchAction(action);
        UrlParamsUtil.handleDispatchOpenLinkedTabAction(this.props);
    };

    private handleSetNoSpecial = () => false;
    private isPopupEnabled(): boolean {
        const { options, dynamicFilter } = this.props;
        return options.showDateStayPopup ? (!options.dateRangePicker ? !dynamicFilter.stayperioddefid || !dynamicFilter.startdate : !dynamicFilter.startdate || !dynamicFilter.enddate) : false;
    }

    private handleSubjectPopup = () => {
        const { dispatchAction } = this.props;
        const action = {
            type: ActionType.DynamicFilter,
            filter: dynamicFilterType.subjectPopup,
            payload: { subjectPopup: true },
        };
        dispatchAction(action);
    };

    private getSmartButtonContent = (): SmartButtonContent | null => {
        const { options, availabilityState, context } = this.props;
        const localeId = context.currentLocale.locale;
        const localContent = options.localized?.find((lc) => lc.locale === localeId);
        if (options.smartButton && options.smartButtonConditions) {
            const smartButtonConditions: string[] = options.smartButtonConditions.map((item) => item.value.toString());
            if (smartButtonConditions.includes("dates") && this.isPopupEnabled()) {
                return { smartButtonLabel: localContent?.smartButtonDateLabel || "", smartButtonCondition: "date" };
            }
            if (smartButtonConditions.includes("subjects") && !availabilityState.availabilityResult?.filter.subjects?.length) {
                return { smartButtonLabel: localContent?.smartButtonSubjectLabel || "", smartButtonCondition: "subject" };
            }
        }
        return null;
    };

    private handleBookButtonClick = (onClickHandler: () => void) => () => {
        const smartButtonCondition = this.getSmartButtonContent()?.smartButtonCondition;
        if (smartButtonCondition === "subject") {
            this.handleSubjectPopup();
        }
        onClickHandler();
        UrlParamsUtil.handleDispatchOpenLinkedTabAction(this.props);
    };

    private loadLabels = () => {
        const {
            options,
            context: { currentLocale, site },
        } = this.props;
        if (options.showStartEndDateLabel) {
            const startDatePickerElement = document.getElementsByName("crpButtonDateRangePickerStartDate");
            const startDateLabelElement = document.createElement("span");
            startDateLabelElement.setAttribute("class", "start-date-label");
            startDateLabelElement.innerHTML = `${getI18nLocaleString(namespaceList.admin, "arrivalLabelAbovePlaceholder", currentLocale, site)}`;
            const endDatePickerElement = document.getElementsByName("crpButtonDateRangePickerEndDate");
            const endDateLabelElement = document.createElement("span");
            endDateLabelElement.setAttribute("class", "end-date-label");
            endDateLabelElement.innerHTML = `${getI18nLocaleString(namespaceList.admin, "departureLabelAbovePlaceholder", currentLocale, site)}`;
            if (options.showStartEndDateLabel) {
                if (startDatePickerElement) {
                    startDatePickerElement.forEach((item) => item.after(startDateLabelElement));
                }
                if (endDatePickerElement) {
                    endDatePickerElement.forEach((item) => item.after(endDateLabelElement));
                }
            }
        }
    };

    private getBookButton = (onClickHandler: () => void): JSX.Element => {
        const {
            options,
            context: { currentLocale, site },
            className,
            dynamicFilter,
            frontendPageEditState: { isFrontEndEditable },
        } = this.props;
        const { link } = this.state;
        const localeContent = getLocalizedContent({ currentLocale, site, localizedContent: options.localized });
        const smartButtonContent = this.getSmartButtonContent();
        const showSelectedUnitPreference = options.addPreferenceUnitToReservation && dynamicFilter.unitPreference;
        return (
            <>
                <Button
                    id={"result-panel-button"}
                    className={classNames("button cursor-pointer", className, getPreconfiguredResultButtonClassNames(options.buttonConfiguration))}
                    style={getInlineStyle(options.buttonConfiguration)}
                    onClick={showSelectedUnitPreference ? undefined : this.handleBookButtonClick(onClickHandler)}
                    target={link.target}
                    disabled={shouldDisableLink(!!isFrontEndEditable)}
                >
                    <ButtonIcon buttonConfiguration={options.buttonConfiguration} />
                    {showSelectedUnitPreference
                        ? getI18nLocaleString(namespaceList.dynamicPlugin, "selected", currentLocale, site)
                        : smartButtonContent?.smartButtonLabel || localeContent?.buttonText || getI18nLocaleString(namespaceList.widgetTypeSearch, "bookNow", currentLocale, site)}
                </Button>
                {showSelectedUnitPreference && (
                    <span onClick={() => this.removeUnitPreference()} className="unit-preference">
                        {getI18nLocaleString(namespaceList.dynamicPlugin, "removeUnitPreference", currentLocale, site)}
                    </span>
                )}
            </>
        );
    };

    private handleBookLinkClick = (bookUrl: string, accommodationType?: AccommodationType, unit?: Unit) => () => {
        UrlParamsUtil.setPageViewEvent(this.props, bookUrl, this.props.accommodationType, unit);
        UrlParamsUtil.handleDispatchOpenLinkedTabAction(this.props);
        if (this.props.options.setAccommodationIdInUrl) {
            const action: FilterChangeAction = {
                type: ActionType.FilterChange,
                filter: dynamicFilterType.previouslySelectedResourceId,
                payload: {
                    previouslySelectedResourceId: accommodationType?.resourceId,
                },
            };
            this.props.dispatchAction(action);
        }
    };

    private updateSelectedMyEnvReservation = (esReservation: MXTS.EsReservationResult, dispatch: Dispatch<FilterChangeAction | AvailabilityAction | MyEnvReducerAction>) => () => {
        dispatch(updateMyEnvState({ selectedReservationId: esReservation.reservation.reservationId, selectedReservation: esReservation }));
    };

    private getBookLink = (accommodationType: any) => {
        const {
            context: { currentLocale, site },
            options,
            dynamicFilter,
        } = this.props;
        const { currentUnit: unit } = this;
        const { modalStartDate, modalStayPeriodDefId, endDate, link, isSelectedBookingRestricted } = this.state;
        const disabled = options.showDateStayPopup
            ? (!options.dateRangePicker && (modalStartDate || dynamicFilter.startdate) && (modalStayPeriodDefId || dynamicFilter.stayperioddefid)) ||
              (options.dateRangePicker && (modalStartDate || dynamicFilter.startdate) && (endDate || dynamicFilter.enddate))
                ? false
                : true
            : false;
        const bookUrl = this.renderBookLink();
        const localeContent = getLocalizedContent({ currentLocale, site, localizedContent: options.localized });
        if (bookUrl && !isSelectedBookingRestricted) {
            return (
                <a
                    href={bookUrl}
                    className={classNames("button button--secondary space-mt-s book-btn", getPreconfiguredResultButtonClassNames(options.buttonConfiguration), {
                        ["disabled"]: disabled,
                    })}
                    style={getInlineStyle(options.buttonConfiguration)}
                    onClick={this.handleBookLinkClick(bookUrl, accommodationType, unit)}
                    title={accommodationType?.resortName || ""}
                    target={link.target}
                >
                    {localeContent?.buttonText || getI18nLocaleString(namespaceList.widgetTypeSearch, "bookNow", currentLocale, site)}
                </a>
            );
        }
        return <div />;
    };

    private handleArrivalDateModal = () => {
        const { modalStartDate, endDate } = this.state;
        let newFocusedInput: FocusedInputShape | null = "startDate";
        if (modalStartDate && !endDate) {
            newFocusedInput = "endDate";
        } else if (modalStartDate && endDate) {
            newFocusedInput = null;
        }
        this.setState({ arrivalDateModalOpen: !this.state.arrivalDateModalOpen, focusedInput: newFocusedInput });
    };

    private async selectAccoType(accommodationType: any) {
        const { availabilityState, options, context } = this.props;
        const { showSpecialDatesByDefault, showSpecificSpecialDates } = options;
        const dynamicFilter = { ...this.props.dynamicFilter };
        const { specialcode } = dynamicFilter;
        let special;
        if (!accommodationType?.specialName && accommodationType?.specialId) {
            special = await context.mxtsApi
                .resources(availabilityState.env!, { size: 1, resourceIds: [accommodationType.specialId] })
                .then((res: MXTS.PagedResult<MXTS.Resource>) => (res.content.length ? res.content[0] : null));
        }
        this.setState({
            isFetching: true,
            chosenAccoType: accommodationType,
            arrivalDateModalOpen: true,
            focusedInput: null,
            isSpecialSelected: (showSpecialDatesByDefault && !!accommodationType?.specialId) || (showSpecificSpecialDates && !!specialcode),
            unitSpecialName: special?.name,
        });
        const { allBookableDurations } = this.state;
        let arrivalDateMap: DateMap | undefined = this.state.arrivalDateMap;
        let durations: number[] | undefined = this.state.durations;
        let specialArrivalDateMap: DateMap | undefined = this.state.specialArrivalDateMap;
        let specialDurations: number[] | undefined = this.state.specialDurations;
        let stayPeriodDefs: MXTS.StayPeriodDef[] | undefined = this.state.modalAvailableStayPeriodDefs;
        let specialStayPeriodDefs: MXTS.StayPeriodDef[] | undefined = this.state.modalAvailableSpecialStayPeriodDefs;
        if (options.disableDefaultDuration) {
            dynamicFilter.stayperioddefid = undefined;
        }
        if (this.props.options.dateRangePicker) {
            getArrivalDepartureModel(
                this.props.context,
                availabilityState.env!,
                dynamicFilter,
                accommodationType.resourceId,
                accommodationType.unitId,
                undefined,
                accommodationType.specialCode || [NO_SPECIAL]
            ).then((arrivalDepartureModal) => {
                arrivalDateMap = arrivalDepartureModal.arrivalDateMap;
                durations = options.disableDefaultDuration ? allBookableDurations : arrivalDepartureModal.durations;
                specialArrivalDateMap = arrivalDepartureModal.specialArrivalDateMap;
                specialDurations = arrivalDepartureModal.specialDurations;
                this.setState({
                    isFetching: false,
                    focusedInput: this.props.dynamicFilter.startdate ? "endDate" : "startDate",
                    modalStartDate: this.props.dynamicFilter.startdate ? moment(this.props.dynamicFilter.startdate, DATE_FORMAT.DEFAULT) : null,
                    modalStayPeriodDefId: this.props.dynamicFilter.stayperioddefid || null,
                    endDate: this.props.dynamicFilter.enddate ? moment(this.props.dynamicFilter.enddate, DATE_FORMAT.DEFAULT) : null,
                    arrivalDateMap,
                    durations,
                    specialArrivalDateMap,
                    specialDurations,
                });
            });
        } else {
            const { modalStartDate } = this.state;
            getArrivalDateStayModel(
                this.props.context,
                availabilityState.env!,
                dynamicFilter,
                modalStartDate || null,
                null,
                this.state.allStayPeriodDefs || [],
                accommodationType.resourceId,
                accommodationType.unitId,
                accommodationType.specialCode || [NO_SPECIAL],
                true
            ).then((arrivalStayModal) => {
                arrivalDateMap = arrivalStayModal.arrivalDateMap;
                stayPeriodDefs = arrivalStayModal.stayPeriodDefs;
                specialArrivalDateMap = arrivalStayModal.specialArrivalDateMap;
                specialStayPeriodDefs = arrivalStayModal.specialStayPeriodDefs;
                this.setState({
                    isFetching: false,
                    modalStartDate: DynamicResultsPanelButtonBase.getModalStartDate(this.props, modalStartDate),
                    modalStayPeriodDefId: this.props.dynamicFilter.stayperioddefid || null,
                    arrivalDateMap,
                    modalAvailableStayPeriodDefs: stayPeriodDefs,
                    specialArrivalDateMap,
                    modalAvailableSpecialStayPeriodDefs: specialStayPeriodDefs,
                });
            });
        }
    }

    private handleModalStartDateChange = (modalStartDate: Moment): void => {
        const { currentAccommodationType: accommodationType, currentUnit: unit } = this;
        const { modalStartDate: prevModalStartDate } = this.state;
        if (moment(modalStartDate).format("DDMMYYYY") !== (prevModalStartDate && moment(prevModalStartDate).format("DDMMYYYY"))) {
            const { availabilityState } = this.props;
            const dynamicFilter = { ...this.props.dynamicFilter };
            this.setState({ isFetching: true, modalStartDate });
            getArrivalDateStayModel(
                this.props.context,
                availabilityState.env!,
                dynamicFilter,
                modalStartDate || null,
                null,
                this.state.allStayPeriodDefs!,
                accommodationType?.resourceId,
                unit?.unitId,
                accommodationType?.specialCode || unit?.specialCode || [NO_SPECIAL],
                true
            ).then((arrivalStayModal) => {
                this.setState({
                    isFetching: false,
                    modalStartDate,
                    modalStayPeriodDefId: this.props.dynamicFilter.stayperioddefid || null,
                    arrivalDateMap: arrivalStayModal.arrivalDateMap,
                    modalAvailableStayPeriodDefs: arrivalStayModal.stayPeriodDefs,
                    specialArrivalDateMap: arrivalStayModal.specialArrivalDateMap,
                    modalAvailableSpecialStayPeriodDefs: arrivalStayModal.specialStayPeriodDefs,
                });
            });
        } else {
            this.setState({ modalStartDate });
        }
    };

    private handleModalStayChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        const modalStayPeriodDefId = value !== "null" ? +value : null;
        this.setState({ modalStayPeriodDefId });
    };

    // eslint-disable-next-line max-lines-per-function
    private getArrivalDepartureDatePopup = () => {
        const {
            modalStartDate,
            arrivalDateModalOpen,
            endDate,
            focusedInput,
            isFetching,
            chosenAccoType,
            isSpecialSelected,
            specialDurations,
            specialArrivalDateMap,
            allBookableDurations,
            durations,
            arrivalDateMap,
            isSelectedBookingRestricted,
            unitSpecialName,
        } = this.state;
        const {
            dynamicFilter,
            options,
            context: { currentLocale, site },
        } = this.props;
        if (options.disableDefaultDuration && arrivalDateModalOpen && !allBookableDurations?.length) {
            if (dynamicFilter.stayperioddefid && dynamicFilter.defaultStay === +dynamicFilter.stayperioddefid) {
                this.loadAllBookableDurations();
            }
        }
        const { currentAccommodationType: accommodationType, currentResort: resort } = this;
        const specialName = chosenAccoType?.specialName || unitSpecialName;
        const startDateId = "crpButtonDateRangePickerStartDate";
        const endDateId = "crpButtonDateRangePickerEndDate";
        const minimumStayMsg = getLocalizedContent({ site, currentLocale, localizedContent: options.localizedMinimumStayMsg })?.minimumStayMsg || "";
        const specialButtonNameHtml = `<strong>${specialName?.toString()} </strong>`;
        const localizedButtonNameText =
            (options.enableToggleButtonText && getLocalizedContent({ site, currentLocale, localizedContent: options.localizedSpecialToggleText })?.specialNameButtonText) || "";
        const specialButtonContent = localizedButtonNameText?.replace("[x]", specialButtonNameHtml);
        const restrictedBookingMessage = accommodationType?.localizedBookingIsRestrictedMsg
            ? getLocalizedContent({ site, currentLocale, localizedContent: accommodationType.localizedBookingIsRestrictedMsg })?.bookingIsRestrictedMsg
            : "";
        return (
            <Modal
                isOpen={arrivalDateModalOpen}
                toggle={this.handleArrivalDateModal}
                className={classNames("result-panel-button modal-dates-calendar modal-dates-calendar-mobile", {
                    "modal-horizontal":
                        isLandscapeOrientation() &&
                        ((options.orientationOfCalendar === "horizontal" && !this.state.isMobile) || (options.orientationOfCalendar === "horizontal" && isLandscapeOrientation())),
                    "modal-vertical":
                        (this.state.isMobile && isPortraitOrientation()) ||
                        (options.orientationOfCalendar === "vertical" && !this.state.isMobile && isPortraitOrientation()) ||
                        (options.orientationOfCalendar === "horizontal" && this.state.isMobile) ||
                        (options.orientationOfCalendar === "vertical" && !this.state.isMobile),
                })}
            >
                <ModalHeader tag="h4" toggle={this.handleArrivalDateModal} className="font-brand">
                    {getI18nLocaleString(namespaceList.widgetSearchfacet, "selectArrivalDepartureDate", currentLocale, site)}
                </ModalHeader>
                <ModalBody className="modal-date-picker">
                    {options.repositionCalendar && isFetching && (
                        <div className="calendar-loader">
                            <div className="calendar-overlay" />
                            <FontAwesome name="spinner" className={classNames("searchfacet-progress", "in-progress")} />
                            <div style={{ display: isFetching ? "block" : "none" }}>
                                <DateRangePickerWrapper
                                    startDatePlaceholderText={getI18nLocaleString(namespaceList.admin, "startDate")}
                                    endDatePlaceholderText={getI18nLocaleString(namespaceList.admin, "endDate")}
                                    startDate={modalStartDate}
                                    endDate={endDate}
                                    onDatesChange={this.handleDatesChange}
                                    onFocusChange={this.handleFocusChange}
                                    keepFocusOnInput
                                    orientation={this.state.isMobile ? "vertical" : options.orientationOfCalendar || "horizontal"}
                                    displayFormat={this.props.options.dateFormat || DATE_FORMAT.DISPLAY}
                                    focusedInput={null}
                                    startDateId="startDateLoading"
                                    endDateId="endDateLoading"
                                />
                                <div className="filter-footer">{(resort || accommodationType) && this.getBookLink((resort as Resort)?.curatedAccommodation || accommodationType)}</div>
                            </div>
                        </div>
                    )}
                    <Card style={options.repositionCalendar ? { display: isFetching ? "none" : "block" } : {}}>
                        <DateRangePickerWrapper
                            startDateId={startDateId}
                            endDateId={endDateId}
                            startDate={modalStartDate}
                            endDate={endDate}
                            onDatesChange={this.handleDatesChange}
                            focusedInput={focusedInput}
                            onFocusChange={this.handleFocusChange}
                            availableDates={arrivalDateMap}
                            availableDurations={durations}
                            specialAvailableDates={specialArrivalDateMap}
                            specialAvailableDurations={specialDurations}
                            showHighlightedDates={true}
                            showOnlySpecialDates={isSpecialSelected}
                            loading={options.repositionCalendar ? false : isFetching}
                            keepFocusOnInput
                            showBackdrop={false}
                            minimumStayMsg={options.dateRangePicker && options.enableMinimumStayMsg ? minimumStayMsg : ""}
                            orientation={this.state.isMobile ? "vertical" : options.orientationOfCalendar || "horizontal"}
                            onLoadComplete={this.loadLabels}
                            displayFormat={this.props.options.dateFormat || DATE_FORMAT.DISPLAY}
                        />
                        {chosenAccoType?.specialId && specialName && (focusedInput === "startDate" || (specialArrivalDateMap && !isEmpty(specialDurations))) && (
                            <SpecialLegend
                                isSelected={isSpecialSelected}
                                onClick={this.handleSpecialClick}
                                specialName={chosenAccoType.specialName}
                                useToggleButton={options.useToggleButton}
                                specialButtonContent={specialButtonContent}
                            />
                        )}
                        <div className="filter-footer">
                            {isSelectedBookingRestricted && (
                                <small className={"alert alert-warning feedback-booking-restriction"}>
                                    <span>{restrictedBookingMessage}</span>
                                </small>
                            )}
                            {(resort || accommodationType) && this.getBookLink((resort as Resort)?.curatedAccommodation || accommodationType)}
                        </div>
                    </Card>
                </ModalBody>
            </Modal>
        );
    };

    private changeOrientation = () => {
        if (isPortraitOrientation() && !this.state.isMobile) {
            this.setState({ isMobile: true });
        } else if (isLandscapeOrientation() && this.state.isMobile) {
            this.setState({ isMobile: false });
        }
    };

    private handleSpecialClick = () => {
        this.setState({ isFetching: true, isSpecialSelected: !this.state.isSpecialSelected, focusedInput: null, modalStartDate: null, endDate: null }, () => {
            this.setState({ isFetching: false, focusedInput: START_DATE });
        });
    };

    private handleDatesChange = async ({ startDate, endDate }: { startDate: Moment; endDate: Moment }) => {
        let durations: number[] | undefined = this.state.durations;
        let specialDurations: number[] | undefined = this.state.specialDurations;
        const { modalStartDate: prevModalStartDate } = this.state;
        if (moment(startDate).format("DDMMYYYY") !== (prevModalStartDate && moment(prevModalStartDate).format("DDMMYYYY"))) {
            const { availabilityState, options } = this.props;
            const { currentAccommodationType: accommodationType, currentUnit: unit } = this;
            const dynamicFilter = { ...this.props.dynamicFilter };
            this.setState({ isFetching: true, modalStartDate: startDate, endDate: null });
            const [arrivalDepartureModal, allBookableDurations] = await Promise.all([
                getArrivalDepartureModel(
                    this.props.context,
                    availabilityState.env!,
                    dynamicFilter,
                    accommodationType ? accommodationType.resourceId : undefined,
                    unit ? unit.unitId : undefined,
                    startDate ? moment(startDate).format(DATE_FORMAT.ELASTIC) : undefined,
                    accommodationType?.specialCode || unit?.specialCode || [NO_SPECIAL]
                ),
                options.disableDefaultDuration ? this.getAllBookableDurationsWithoutStayPeriodFilter(startDate) : Promise.resolve([]),
            ]);
            durations = options.disableDefaultDuration ? allBookableDurations : arrivalDepartureModal.durations;
            specialDurations = arrivalDepartureModal.specialDurations;
            this.setState({ modalStartDate: startDate, durations, focusedInput: "endDate", isFetching: false, specialDurations }, () => this.loadLabels());
        } else {
            this.setState({ modalStartDate: startDate, endDate, focusedInput: null });
        }
    };

    private handleStartDateFocusChange = () => {
        return;
    };

    private handleFocusChange = (focusedInput: FocusedInputShape | null): void => {
        const { modalStartDate, endDate } = this.state;
        let focus = focusedInput;
        if (!modalStartDate && focusedInput === "endDate" && !this.state.focusedInput) {
            focus = "startDate";
        }
        if ((!modalStartDate || !endDate) && !focusedInput) {
            focus = this.state.focusedInput!;
        }
        if (!focusedInput) {
            focus = this.state.focusedInput!;
        }
        this.setState({ focusedInput: focus });
    };

    private shouldAddSpecialParam = ({ startDate, endDate, stay }: { startDate?: string; endDate?: string; stay?: string }) => {
        const { specialArrivalDateMap, specialDurations, modalAvailableSpecialStayPeriodDefs } = this.state;
        const {
            options: { dateRangePicker },
        } = this.props;
        const formattedStartDate = startDate ? moment(startDate, DATE_FORMAT.DEFAULT) : null;
        const formattedEndDate = endDate ? moment(endDate, DATE_FORMAT.DEFAULT) : null;
        const hasSpecialParam = UrlParamsUtil.shouldAddSpecialParam({
            specialAvailableDates: specialArrivalDateMap,
            specialAvailableDurations: specialDurations,
            startDate: formattedStartDate,
            endDate: formattedEndDate,
            specialAvailableStayPeriodDefs: modalAvailableSpecialStayPeriodDefs,
            stayPeriodDefCode: stay,
            dateRangePicker,
        });
        return hasSpecialParam;
    };

    // eslint-disable-next-line max-lines-per-function
    private renderBookLink = () => {
        const { modalStartDate, endDate, chosenAccoType, content, link } = this.state;
        const { options, context, dynamicFilter, availabilityState, myEnvState, isMyEnvWidget } = this.props;
        const { currentAccommodationType: accommodationType, currentUnit: unit, currentResort: resort } = this;
        const localeId = context.currentLocale.locale;
        const localContent = options.linking.localizedLinkButtonOptions?.find((lc) => lc.locale === localeId);
        const locale = context.currentLocale.code;
        const param: UrlLinkParams = UrlParamsUtil.getUrlParamsFromFilter(dynamicFilter);
        if (locale && unit) {
            param.lan = locale;
        }
        const startdate = modalStartDate?.format(DATE_FORMAT.DEFAULT);
        param.startdate = startdate || (dynamicFilter ? dynamicFilter.startdate : moment(accommodationType!.arrivalDate).format(DATE_FORMAT.DEFAULT));

        if (options.dateRangePicker) {
            param.enddate =
                dynamicFilter.enddate ||
                (endDate
                    ? endDate.format(DATE_FORMAT.DEFAULT)
                    : chosenAccoType
                    ? moment(chosenAccoType.arrivalDate).add(chosenAccoType?.duration?.[0], "days").format(DATE_FORMAT.DEFAULT)
                    : undefined);
        } else {
            param.stay = chosenAccoType?.stayPeriodDefCode || accommodationType?.stayPeriodDefCode;
        }

        if (resort) {
            param.resortid = resort.resortId;
        }

        param.resourceid = (resort as Resort)?.curatedAccommodation?.resourceId || chosenAccoType?.resourceId || accommodationType?.resourceId || unit?.resourceId || dynamicFilter?.resourceid;

        if (dynamicFilter.unitid) {
            if (localContent?.useExternalUrl) {
                param.objectid = dynamicFilter.unitid;
            } else {
                param.unitid = dynamicFilter.unitid;
            }
        } else if (unit?.unitId) {
            if (localContent?.useExternalUrl) {
                param.objectid = unit.unitId;
            } else {
                param[isMyEnvWidget ? "selectedOwnerUnitId" : "unitid"] = unit.unitId;
            }
        }
        if (dynamicFilter.amenities?.length && this.state.amenityCodes?.length && !param.amenities?.length) {
            // Filtering all dynamic filter amenity and only sending those codes
            param.amenity = Array.from(this.state.amenityCodes.values()).join();
        }
        const specialCodes: string[] | undefined = (content as AccommodationType | Unit | undefined)?.specialCode;
        if (
            specialCodes?.length &&
            !specialCodes.includes(NO_SPECIAL) &&
            this.shouldAddSpecialParam({
                startDate: param.startdate,
                endDate: param.enddate,
                stay: param.stay,
            })
        ) {
            param.specialcode = specialCodes;
        }

        if (accommodationType?.alternative) {
            // Note:- This check is for flexible days, if flexible dates are available then take that date for book now next page.
            const flexiStartDate = moment(accommodationType?.arrivalDate, DATE_FORMAT.MXTS).format(DATE_FORMAT.DEFAULT);
            const flexiEndDate = moment(accommodationType?.departureDate, DATE_FORMAT.MXTS).format(DATE_FORMAT.DEFAULT);
            if (flexiStartDate !== dynamicFilter.startdate) {
                param.startdate = flexiStartDate;
            }
            if (flexiEndDate !== dynamicFilter.enddate) {
                param.enddate = flexiEndDate;
            }
        }

        if (options.disableDefaultDuration && param.enddate && param.stayperioddefid) {
            // If we allow selecting an endDate that doesn't match the stayPeriodDefId due to options.disableDefaultDuration,
            //  then we need to make sure we are not overriding the endDate with the default stayPeriod
            delete param.stayperioddefid;
        }

        if (!param.stateUuid && isMyEnvWidget && !param.selectedOwnerUnitId && myEnvState.ownerState?.selectedUnitId) {
            param.selectedOwnerUnitId = myEnvState.ownerState.selectedUnitId;
        }

        const connector = link.url?.indexOf("?") > -1 ? "&" : "?";
        let bookLink = `${link.url}${connector}${stringify(param, { encode: false, strict: false })}`;
        if (link.anchor) {
            bookLink = `${bookLink}#${link.anchor}`;
        }
        const restrictedResourceIds: number[] = [];
        const resourceIds = chosenAccoType ? [chosenAccoType.resourceId] : [];
        const allBookRestrictions: MXTS.BookRestrictions[] = [];

        getAllBookRestrictionsByResourceIds(context.mxtsApi, resourceIds, availabilityState.env!).then((restrictions: MXTS.BookRestrictions[]) => {
            allBookRestrictions.push(...restrictions);
            if (allBookRestrictions.length) {
                for (const resourceId of resourceIds) {
                    const restrictedResources = allBookRestrictions.filter((bookRestriction) => bookRestriction.resourceId === resourceId);
                    const restrictedOption = validateBookRestrictionConditions(restrictedResources, dynamicFilter, startdate);
                    if (restrictedOption === RESTRICTION_RULE_OPTIONS.BOOK_RESTRICTION) {
                        restrictedResourceIds.push(resourceId);
                    }
                }
            }
            this.setState({ isSelectedBookingRestricted: restrictedResourceIds.some((resourceId: number) => resourceId === resourceId) });
        });
        return bookLink;
    };

    private addPreferenceUnitToReservationLink(unit: Unit) {
        return this.getBookButton(() => this.addUnitPreference(unit));
    }

    private addUnitPreference(unit: Unit) {
        const action: FilterChangeAction = {
            type: ActionType.FilterChange,
            filter: dynamicFilterType.unitPreference,
            payload: {
                unitPreference: true,
                unitid: unit.unitId,
            },
        };
        this.props.dispatchAction(action);
    }

    private removeUnitPreference() {
        const action: FilterChangeAction = {
            type: ActionType.FilterChange,
            filter: dynamicFilterType.unitPreference,
            payload: {},
        };
        this.props.dispatchAction(action);
    }

    private getAllBookableDurationsWithoutStayPeriodFilter = async (startDate?: Moment): Promise<number[]> => {
        const { dynamicFilter, availabilityState } = this.props;
        const { currentAccommodationType: accommodationType, currentUnit: unit } = this;
        const dynamicFilterWithoutStayPeriod = { ...dynamicFilter, stay: undefined, stayperioddefid: undefined, defaultStay: undefined };
        const arrivalDepartureModal = await getArrivalDepartureModel(
            this.props.context,
            availabilityState.env!,
            dynamicFilterWithoutStayPeriod,
            accommodationType ? accommodationType.resourceId : undefined,
            unit ? unit.unitId : undefined,
            startDate ? moment(startDate).format(DATE_FORMAT.ELASTIC) : undefined,
            [NO_SPECIAL],
            true
        );
        return arrivalDepartureModal.durations || [];
    };

    private getAllBookableDurations = async (): Promise<number[]> => {
        const { context, dynamicFilter } = this.props;
        let allBookableDurations: number[] = [];

        const customAggregations: MXTS.Aggregation[] = [
            {
                name: "DURATION_FACET",
                field: "DURATION",
                type: "FACET",
                excludeFields: ["DURATION"],
                size: 1000,
            },
        ];

        const unfilteredAvailability = await AvailabilityUtil.getUnfilteredAvailability(context, dynamicFilter, { customAggregations });
        const allDurations = unfilteredAvailability.availabilityResult.response.durations;

        if (unfilteredAvailability && allDurations) {
            allBookableDurations = allDurations;
        }

        return allBookableDurations;
    };

    private checkIsUnavailableAccommodation() {
        const { currentAccommodationType: accommodationType } = this;
        const { isMapWidget, availabilityState } = this.props;
        let isUnavailableAccommodation = accommodationType?.reservable === false;
        if (isMapWidget && accommodationType) {
            isUnavailableAccommodation = availabilityState.availabilityResult?.response.resources?.find((resource) => resource.resourceId === accommodationType.resourceId)?.reservable === false;
        }
        return isUnavailableAccommodation || accommodationType?.isBookingRestricted;
    }

    private static getModalStartDate(props: DynamicButtonProps, modalStartDate: moment.Moment | null = null) {
        const { accommodationType, dynamicFilter } = props;
        let updatedModalStartDate: null | moment.Moment = dynamicFilter.startdate ? moment(dynamicFilter.startdate, DATE_FORMAT.DEFAULT) : modalStartDate;
        if (accommodationType?.alternative) {
            const flexiStartDate = moment(accommodationType?.arrivalDate, DATE_FORMAT.MXTS).format(DATE_FORMAT.DEFAULT);
            if (flexiStartDate !== dynamicFilter.startdate) {
                updatedModalStartDate = moment(flexiStartDate, DATE_FORMAT.DEFAULT);
            }
        }
        return updatedModalStartDate;
    }

    private async checkActivityDetailsIdRequirement() {
        const { context, activity, availabilityState } = this.props;
        const { env } = availabilityState;
        if (activity && env) {
            const activitySections = await getActivitySections(context, env, activity);
            const numberOfSections = new Set(activitySections.map((section) => section.resourceId)).size;
            this.setState({ showDetailsIdInUrl: !(numberOfSections > 1) });
        }
    }
}

function mapStateToProps(state: State): DynamicButtonStoreProps {
    return {
        myEnvState: state.myEnvState,
        dynamicFilter: state.dynamicFilter,
        availabilityState: state.availabilityState,
        frontendPageEditState: state.frontendPageEditState,
    };
}

function mapDispatchToProps(dispatch: Dispatch<FilterChangeAction | AvailabilityAction | MyEnvReducerAction | PriceMatrixAction>): DynamicButtonDispatchProps {
    return { dispatchAction: dispatch };
}

// eslint-disable-next-line max-len
const ResultsPanelButton = connect<DynamicButtonStoreProps, DynamicButtonDispatchProps>(mapStateToProps, mapDispatchToProps)(DynamicResultsPanelButtonBase);

export const ResultsPanelButtonWidget = wrapProps<DynamicButtonBaseProps>(ResultsPanelButton);
