import * as React from "react";

import { ACTIVITY, RESORT, descriptionTypeMultiSelector, dynamicFieldPrice, getContentTypeSelector, getDateFormat } from "../../../components/utils";
import { ButtonConfigurationOptions, ButtonConfigurationSpec, defaultButtonConfigurationValues } from "../../../utils/button.util";
import { DynamicResultsPanelButtonBase, ResultsPanelButtonWidget } from "./ResultsPanelButton";
import { FormSpec, localized, multiSelectStylePicker } from "../../../form-specs";
import { LinkingSpecOptions, conditionalLinkingSpec, linkingSpec } from "../../../inputSpecs/linkingSpec";
import { getLinkFromLinkingSpec, linkingSpecDefaultValues } from "../../../utils/linking.util";

import { DATE_FORMAT } from "../../../utils/constants";
import { NumberMultiSelectOption } from "../../mxts/selectOption.types";
import { TypesearchContainerWidgetSpec } from "../../widget";
import { WidgetGroup } from "../../widget.enum";
import { WithId } from "@maxxton/cms-api";
import { findMultiSelectStyleClassNames } from "../../../themes";
import { getI18nLocaleObject } from "../../../i18n";
import { isServerSide } from "../../../utils/generic.util";
import namespaceList from "../../../i18n/namespaceList";
import { tabLinkAutocomplete } from "../../../inputSpecs";

export interface ResultsPanelButtonWidgetOptions extends WithId {
    styleIds: any[];
    localized: LocalizedButton[];
    dateRangePicker: boolean;
    showDateStayPopup: boolean;
    addPreferenceUnitToReservation?: boolean;
    showQuote: boolean;
    toggleButton: boolean;
    ElementToToggle: string;
    showDateStayPopupInfo?: string;
    tabLink?: string;
    contentTypeSelector?: boolean;
    contentType?: string;
    resortMultiSelector?: NumberMultiSelectOption[];
    resourceId?: number;
    unitId?: number;
    smartButton: boolean;
    smartButtonConditions: NumberMultiSelectOption[];
    showTravelGroupPopup: boolean;
    disableDefaultDuration: boolean;
    showSpecialDatesByDefault?: boolean;
    showSpecificSpecialDates?: boolean;
    useToggleButton?: boolean;
    linking: LinkingSpecOptions;
    buttonConfiguration: ButtonConfigurationOptions;
    enableMinimumStayMsg: boolean;
    localizedMinimumStayMsg: LocalizedMinimumStayMsg[];
    enableToggleButtonText: boolean;
    localizedSpecialToggleText: localizedSpecialToggleText[];
    orientationOfCalendar: "horizontal" | "vertical";
    showStartEndDateLabel: boolean;
    removedResourceIdFromUrlParams?: boolean;
    dateFormat: string;
    repositionCalendar?: boolean;
    useWithActivityPlanner?: boolean;
    useDynamicFieldAsLink?: boolean;
    descriptionTypeMultiSelector?: NumberMultiSelectOption[];
    dynamicFieldCodes?: NumberMultiSelectOption[];
    openInNewTab?: boolean;
    addTheSelectedReservationIdToTheUrl?: boolean;
    setAccommodationIdInUrl?: boolean;
    showActivityPlannerButtonLabel?: boolean;
}

export interface LocalizedMinimumStayMsg {
    minimumStayMsg: string;
    locale: string;
}

export interface localizedSpecialToggleText {
    specialNameButtonText: string;
    locale: string;
}
export interface LocalizedButton {
    locale: string;
    buttonText: string;
    quoteLinkUrl: string;
    quoteButtonText: string;
    smartButtonDateLabel: string;
    smartButtonSubjectLabel: string;
    freeActivityButtonText?: string;
}

const TARGETS = ["button", "button-model"];

const widgetOptionsForm: FormSpec<ResultsPanelButtonWidgetOptions> = {
    id: "dynamicButton-widget-options",
    name: getI18nLocaleObject(namespaceList.dynamicButtonWidget, "dynamicButtonWidgetOption"),
    pluralName: getI18nLocaleObject(namespaceList.dynamicButtonWidget, "dynamicButtonWidgetOptions"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.widgetButton, "general"),
                    properties: [
                        [
                            {
                                variable: "useDynamicFieldAsLink",
                                label: getI18nLocaleObject(namespaceList.widgetButton, "useDynamicFieldAsLink"),
                                type: "checkbox",
                            },
                            ...getContentTypeSelector(),
                            descriptionTypeMultiSelector("descriptionTypeMultiSelector"),
                            {
                                ...dynamicFieldPrice("dynamicFieldCodes", undefined, undefined, "Activity"),
                                visible: (options: ResultsPanelButtonWidgetOptions) => !!options.useDynamicFieldAsLink && options.contentType === ACTIVITY,
                            },
                            {
                                ...dynamicFieldPrice("dynamicFieldCodes"),
                                visible: (options: ResultsPanelButtonWidgetOptions) => !!options.useDynamicFieldAsLink && options.contentType !== ACTIVITY,
                            },
                            {
                                variable: "removedResourceIdFromUrlParams",
                                label: getI18nLocaleObject(namespaceList.widgetButton, "removedResourceIdFromUrlParams"),
                                type: "checkbox",
                                visible: (options: any): boolean => options.contentType === RESORT && !!options.contentTypeSelector,
                                default: false,
                            },
                            {
                                variable: "toggleButton",
                                label: getI18nLocaleObject(namespaceList.widgetButton, "toggleButton"),
                                type: "checkbox",
                            },
                            {
                                variable: "ElementToToggle",
                                label: getI18nLocaleObject(namespaceList.widgetButton, "ElementToToggle"),
                                type: "text",
                                visible: (options: ResultsPanelButtonWidgetOptions) => options.toggleButton,
                            },
                            localized({
                                variable: "localized",
                                tabContent: [
                                    {
                                        variable: "buttonText",
                                        label: getI18nLocaleObject(namespaceList.widgetButton, "buttonText"),
                                        type: "text",
                                    },
                                ],
                            }),
                            {
                                variable: "openInNewTab",
                                label: getI18nLocaleObject(namespaceList.widgetButton, "openInNewTab"),
                                type: "checkbox",
                                visible: (options: ResultsPanelButtonWidgetOptions) => !!options.useDynamicFieldAsLink,
                                default: false,
                            },
                            {
                                variable: "useWithActivityPlanner",
                                label: getI18nLocaleObject(namespaceList.admin, "useWithActivityPlanner"),
                                type: "checkbox",
                            },
                            {
                                variable: "showActivityPlannerButtonLabel",
                                label: getI18nLocaleObject(namespaceList.widgetActivityPlanner, "showActivityPlannerButtonLabel"),
                                type: "checkbox",
                            },
                            {
                                variable: "addTheSelectedReservationIdToTheUrl",
                                label: getI18nLocaleObject(namespaceList.admin, "addTheSelectedReservationIdToTheUrl"),
                                type: "checkbox",
                            },
                            localized({
                                variable: "localized",
                                tabContent: [
                                    {
                                        variable: "freeActivityButtonText",
                                        label: getI18nLocaleObject(namespaceList.widgetResultsPrice, "freeActivity"),
                                        type: "text",
                                    },
                                ],
                                visible: (options: ResultsPanelButtonWidgetOptions) => !!options.useWithActivityPlanner,
                            }),
                            {
                                variable: "showDateStayPopupInfo",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "showDateStayPopupInfo"),
                                type: "paragraph",
                                visible: (options: ResultsPanelButtonWidgetOptions) => options.showDateStayPopup,
                            },
                            {
                                variable: "showDateStayPopup",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "showDateStayPopup"),
                                type: "checkbox",
                            },
                            {
                                variable: "dateRangePicker",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "dateRangePicker"),
                                type: "checkbox",
                                visible: (options: ResultsPanelButtonWidgetOptions) => options.showDateStayPopup,
                            },
                            {
                                variable: "repositionCalendar",
                                label: getI18nLocaleObject(namespaceList.dynamicButtonWidget, "repositionCalendar"),
                                type: "checkbox",
                                default: false,
                                visible: (options: ResultsPanelButtonWidgetOptions) => options.showDateStayPopup,
                            },
                            ...getDateFormat("dateFormat"),
                            {
                                variable: "showStartEndDateLabel",
                                label: getI18nLocaleObject(namespaceList.admin, "showStartEndDateLabel"),
                                default: false,
                                type: "checkbox",
                            },
                            {
                                variable: "orientationOfCalendar",
                                label: getI18nLocaleObject(namespaceList.dynamicAvailabilityDate, "orientationOfCalendar"),
                                type: "select",
                                default: "vertical",
                                visible: (options: ResultsPanelButtonWidgetOptions) => options.showDateStayPopup,
                                optionList: [
                                    {
                                        value: "vertical",
                                        label: getI18nLocaleObject(namespaceList.admin, "vertical"),
                                    },
                                    {
                                        value: "horizontal",
                                        label: getI18nLocaleObject(namespaceList.admin, "horizontal"),
                                    },
                                ],
                            },
                            {
                                variable: "enableMinimumStayMsg",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "enableMinimumStayMsg"),
                                type: "checkbox",
                                visible: (options: ResultsPanelButtonWidgetOptions) => options.dateRangePicker,
                            },
                            {
                                type: "paragraph",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "minimumStayMsgExample"),
                                visible: (options: ResultsPanelButtonWidgetOptions) => options.dateRangePicker && options.enableMinimumStayMsg,
                            },
                            localized({
                                variable: "localizedMinimumStayMsg",
                                tabContent: [
                                    {
                                        label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "minimumStayMsg"),
                                        variable: "minimumStayMsg",
                                        type: "text",
                                    },
                                ],
                                visible: (options: ResultsPanelButtonWidgetOptions) => options.dateRangePicker && options.enableMinimumStayMsg,
                            }),
                            {
                                variable: "showSpecialDatesByDefault",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "showSpecialDatesByDefault"),
                                type: "checkbox",
                                visible: (options: ResultsPanelButtonWidgetOptions) => options.showDateStayPopup,
                            },
                            {
                                variable: "showSpecificSpecialDates",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "showSpecificSpecialDates"),
                                type: "checkbox",
                                visible: (options: ResultsPanelButtonWidgetOptions) => options.showDateStayPopup,
                            },
                            {
                                variable: "useToggleButton",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "useToggleButton"),
                                type: "checkbox",
                                visible: (options: ResultsPanelButtonWidgetOptions) => options.showDateStayPopup,
                            },
                            {
                                variable: "enableToggleButtonText",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "enableToggleButtonText"),
                                type: "checkbox",
                                visible: (options: ResultsPanelButtonWidgetOptions) => !!options.useToggleButton && options.showDateStayPopup,
                            },
                            {
                                type: "paragraph",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "specialToggleTextMsgExample"),
                                visible: (options: ResultsPanelButtonWidgetOptions) => !!options.useToggleButton && options.showDateStayPopup && options.enableToggleButtonText,
                            },
                            localized({
                                variable: "localizedSpecialToggleText",
                                tabContent: [
                                    {
                                        label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "specialNameButtonText"),
                                        variable: "specialNameButtonText",
                                        type: "text",
                                    },
                                ],
                                visible: (options: ResultsPanelButtonWidgetOptions) => !!options.useToggleButton && options.showDateStayPopup && options.enableToggleButtonText,
                            }),
                            {
                                variable: "showTravelGroupPopup",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "showTravelGroupPopup"),
                                type: "checkbox",
                            },
                            {
                                variable: "addPreferenceUnitToReservation",
                                label: getI18nLocaleObject(namespaceList.dynamicButtonWidget, "addPreferenceUnitToReservation"),
                                type: "checkbox",
                            },
                            {
                                variable: "showQuote",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "showQuote"),
                                type: "checkbox",
                            },
                            localized({
                                variable: "localized",
                                tabContent: [
                                    {
                                        label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "quoteLinkUrl"),
                                        variable: "quoteLinkUrl",
                                        type: "text",
                                    },
                                    {
                                        label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "quoteButtonText"),
                                        variable: "quoteButtonText",
                                        type: "text",
                                    },
                                ],
                                visible: (options: ResultsPanelButtonWidgetOptions) => options.showQuote,
                            }),
                            {
                                variable: "disableDefaultDuration",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "disableDefaultDuration"),
                                type: "checkbox",
                            },
                            {
                                variable: "setAccommodationIdInUrl",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "setAccommodationIdInUrl"),
                                type: "checkbox",
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "linking"),
                    properties: [
                        [
                            tabLinkAutocomplete("tabLink"),
                            {
                                variable: "smartButton",
                                label: getI18nLocaleObject(namespaceList.dynamicButtonWidget, "smartButton"),
                                type: "checkbox",
                            },
                            localized({
                                variable: "localized",
                                tabContent: [
                                    {
                                        label: getI18nLocaleObject(namespaceList.dynamicButtonWidget, "smartButtonDateLabel"),
                                        variable: "smartButtonDateLabel",
                                        type: "text",
                                    },
                                    {
                                        label: getI18nLocaleObject(namespaceList.dynamicButtonWidget, "smartButtonSubjectLabel"),
                                        variable: "smartButtonSubjectLabel",
                                        type: "text",
                                    },
                                ],
                                visible: (options: ResultsPanelButtonWidgetOptions) => options.smartButton,
                            }),
                            {
                                label: getI18nLocaleObject(namespaceList.dynamicButtonWidget, "smartButtonConditions"),
                                variable: "smartButtonConditions",
                                type: "multiselect" as const,
                                optionList: async () => [
                                    {
                                        text: "Dates",
                                        value: "dates",
                                    },
                                    {
                                        text: "Subjects",
                                        value: "subjects",
                                    },
                                ],
                                visible: (options: ResultsPanelButtonWidgetOptions) => options.smartButton,
                            },
                            linkingSpec<ResultsPanelButtonWidgetOptions, keyof ResultsPanelButtonWidgetOptions>({ variable: "linking" }),
                        ],
                    ],
                    visible: (options: ResultsPanelButtonWidgetOptions) => !options.useDynamicFieldAsLink,
                },
                {
                    name: getI18nLocaleObject(namespaceList.linkingSpec, "conditionalLinking"),
                    properties: [[conditionalLinkingSpec<ResultsPanelButtonWidgetOptions, keyof ResultsPanelButtonWidgetOptions>({ variable: "linking" })]],
                    visible: (options: ResultsPanelButtonWidgetOptions) => !options.useDynamicFieldAsLink,
                },
                {
                    name: getI18nLocaleObject(namespaceList.widgetButton, "styling"),
                    properties: [
                        [
                            multiSelectStylePicker("styleIds", TARGETS),
                            ButtonConfigurationSpec<ResultsPanelButtonWidgetOptions, keyof ResultsPanelButtonWidgetOptions>({ variable: "buttonConfiguration" }),
                        ],
                    ],
                },
            ],
        },
    ],
};

export const resultsPanelDynamicButtonWidget: TypesearchContainerWidgetSpec<ResultsPanelButtonWidgetOptions> = {
    id: "resultsPanelDynamicButtonWidget",
    type: "resultsPanel",
    widgetGroup: WidgetGroup ? WidgetGroup.DYNAMIC : 1,
    name: getI18nLocaleObject(namespaceList.dynamicButtonWidget, "dynamicButtonWidget"),
    description: getI18nLocaleObject(namespaceList.dynamicButtonWidget, "dynamicButtonWidgetDescription"),
    optionsForm: widgetOptionsForm,
    defaultOptions: (): Omit<ResultsPanelButtonWidgetOptions, "_id"> => ({
        styleIds: [],
        linking: linkingSpecDefaultValues,
        localized: [],
        dateRangePicker: false,
        showDateStayPopup: false,
        showQuote: false,
        toggleButton: false,
        ElementToToggle: "",
        smartButton: false,
        smartButtonConditions: [],
        showTravelGroupPopup: false,
        disableDefaultDuration: false,
        buttonConfiguration: defaultButtonConfigurationValues,
        enableMinimumStayMsg: false,
        enableToggleButtonText: false,
        localizedMinimumStayMsg: [],
        localizedSpecialToggleText: [],
        orientationOfCalendar: "horizontal",
        showStartEndDateLabel: false,
        dateFormat: DATE_FORMAT.DISPLAY,
    }),
    async render(widget, context, sitemapPageLinkWidgetOptions, resultOptions) {
        const { linking, styleIds } = widget.options;
        const className = findMultiSelectStyleClassNames(context.theme, TARGETS, styleIds);
        const link = await getLinkFromLinkingSpec({ context, linkingSpecOptions: linking });
        if (isServerSide() && (!resultOptions || Object.keys(resultOptions).length)) {
            await DynamicResultsPanelButtonBase.warmupCache({
                options: widget.options,
                context,
                className,
                url: link.url,
                target: link.target,
                availabilityState: context.reduxStore.store.getState().availabilityState,
                dynamicFilter: context.reduxStore.store.getState().dynamicFilter,
                dispatchAction: context.reduxStore.store.dispatch,
                myEnvState: {},
                frontendPageEditState: context.reduxStore.store.getState().frontendPageEditState,

                ...(resultOptions || {}),
            });
        }

        return <ResultsPanelButtonWidget options={widget.options} context={context} className={className} url={link.url} target={link.target} />;
    },
};
